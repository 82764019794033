
// import qs from 'qs'
import { useRoute } from 'vue-router'
import { getLocalJson, getCloudStorage } from '@/plugin/axios'
import { defineComponent, onBeforeMount, ref, computed } from 'vue'
// swiper
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import SwiperCore, { EffectFade, Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'

SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay])

declare global {
  interface Window { sdkOverlayWebView?: any; }
}

export default defineComponent({
  name: 'AD',
  components: { Swiper, SwiperSlide },
  setup() {
    const route = useRoute()
    const lang = route.query.language || 'zh-CN'
    const serviceId = route.query.serviceId || 'default'
    const adData = ref([])
    const loadedCount = ref(0)

    onBeforeMount(async () => {
      // get local json file
      const url = encodeURIComponent(`ads/${serviceId}/${lang}.json`)
      console.log(url)

      try {
        const res = await getCloudStorage.get(url, { params: { alt: 'media', t: Date.now() } })
        const data = res?.data || []
        adData.value = setData(data)
      }
      catch {
        // default
        const defaultUrl = encodeURIComponent('ads/default/zh-CN.json')
        const res = await getCloudStorage.get(defaultUrl, { params: { alt: 'media', t: Date.now() } })
        const data = res?.data || []
        adData.value = setData(data)
      }
    })

    const imageLoaded = () => {
      loadedCount.value++
      if (loadedCount.value === allImages.value.length)
        callSdk()
    }

    const imageFailed = () => {
      loadedCount.value++
      if (loadedCount.value === allImages.value.length)
        callSdk()
    }

    const callSdk = () => {
      const sdk = window?.sdkOverlayWebView
      if (typeof sdk !== 'undefined')
        sdk.domContentLoadedCallback()
    }

    const allImages = computed(() => {
      return adData.value.flat().map((item: { imageUrl: string }) => item.imageUrl)
    })
    const setData = (data: any) => {
      if (!data) return []
      return data
        .map((item: any) =>
          item.sort((a: any, b: any) => {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
            return 0
          }))
    }
    return {
      adData,
      imageLoaded,
      imageFailed
    }
  }
})
