import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ad-page" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "swiper-button-prev" }
const _hoisted_5 = { class: "swiper-button-next" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_font_icon = _resolveComponent("font-icon")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.adData.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.adData, (row, index) => {
          return (_openBlock(), _createBlock(_component_swiper, {
            key: `row-${index}`,
            class: "ad-slot",
            loop: row.length > 1,
            pagination: {'clickable': true},
            navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
            autoplay: row.length > 1 ? { delay: 3000, disableOnInteraction: false } : false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (item, index) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: `item-${index}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      href: item.url
                    }, [
                      _createElementVNode("img", {
                        src: item.imageUrl,
                        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imageLoaded && _ctx.imageLoaded(...args))),
                        onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.imageFailed && _ctx.imageFailed(...args)))
                      }, null, 40, _hoisted_3)
                    ], 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_font_icon, { icon: "chevron-left" })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_font_icon, { icon: "chevron-right" })
              ])
            ]),
            _: 2
          }, 1032, ["loop", "navigation", "autoplay"]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}