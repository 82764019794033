import axios from 'axios'

const getLocalJson = axios.create({
  baseURL: '/static/json'
})

const getCloudStorage = axios.create({
  baseURL: `${process.env.VUE_APP_CLOUD_SOTRAGE_URL}/storage/v1/b/sdk-f2e/o`
})

export {
  getLocalJson,
  getCloudStorage
}